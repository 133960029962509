import goal1 from "assets/images/sdg/icons/sdg1icon.jpeg";
import goal2 from "assets/images/sdg/icons/sdg2icon.jpeg";
import goal3 from "assets/images/sdg/icons/sdg3icon.jpeg";
import goal4 from "assets/images/sdg/icons/sdg4icon.jpeg";
import goal5 from "assets/images/sdg/icons/sdg5icon.jpeg";
import goal6 from "assets/images/sdg/icons/sdg6icon.jpeg";
import goal7 from "assets/images/sdg/icons/sdg7icon.jpeg";
import goal8 from "assets/images/sdg/icons/sdg8icon.jpeg";
import goal9 from "assets/images/sdg/icons/sdg9icon.jpeg";
import goal10 from "assets/images/sdg/icons/sdg10icon.jpeg";
import goal11 from "assets/images/sdg/icons/sdg11icon.jpeg";
import goal12 from "assets/images/sdg/icons/sdg12icon.jpeg";
import goal13 from "assets/images/sdg/icons/sdg13icon.jpeg";
import goal14 from "assets/images/sdg/icons/sdg14icon.jpeg";
import goal15 from "assets/images/sdg/icons/sdg15icon.png";
import goal16 from "assets/images/sdg/icons/sdg16icon.jpeg";
import goal17 from "assets/images/sdg/icons/sdg17icon.jpeg";
import image1 from "assets/images/sdg/goal1.png";
import image2 from "assets/images/sdg/goal2.png";
import image3 from "assets/images/sdg/goal3.png";
import image4 from "assets/images/sdg/goal4.png";
import image5 from "assets/images/sdg/goal5.png";
import image6 from "assets/images/sdg/goal6.png";
import image7 from "assets/images/sdg/goal7.png";
import image8 from "assets/images/sdg/goal8.png";
import image9 from "assets/images/sdg/goal9.png";
import image10 from "assets/images/sdg/goal10.png";
import image11 from "assets/images/sdg/goal11.png";
import image12 from "assets/images/sdg/goal12.png";
import image13 from "assets/images/sdg/goal13.png";
import image14 from "assets/images/sdg/goal14.png";
import image15 from "assets/images/sdg/goal15.png";
import image16 from "assets/images/sdg/goal16.png";
import image17 from "assets/images/sdg/goal17.png";

export const ORGANIZATION_TYPES = {
  Business: ["Corporation", "LLC", "Partnership"],
  Nonprofit: ["Charitable Organization", "Foundation", "NGO"],
  Government: ["County Government", "National Government", "Parastatal"],
};

export const PROJECT_TYPES = {
  "Afforestation and Reforestation": [
    "Afforestation and Reforestation",
    "Avoided Conversion",
    "Improved Forest Management",
  ],
  Agriculture: [
    "Crop Management",
    "Livestock Management",
    "Manure Management",
    "Rice Cultivation",
    "Soil Management",
  ],
  "Chemical Industries": [
    "Biofuel Production",
    "Charcoal Production and Consumption",
    "Gas Utilization from Oil Wells",
  ],
  Construction: ["Gypsum Concrete Wall Panels Manufacture"],
  "Energy Demand": [
    "Electric Cooking Devices",
    "HH Lighting",
    "Improved Cookstove",
    "Metered LPG",
  ],
  "Energy Distribution": ["Electrical Grids", "Reactive"],
  "Energy Industries": ["Geothermal", "Hydro", "Solar", "Wind"],
  "Fugitive Emissions from Production and Consumption of Halocarbons and Sulfur Hexafluoride":
    ["Hydro-Fluro Carbon", "Sulphur Hexafluoride Production and Use"],
  "Fugitive Emissions from Fuels": [
    "Coal Mining",
    "Oil and Gas Production",
    "Solid Fuel Handling and Storage",
  ],
  "Manufacturing Industries": ["Cement", "Methane Release"],
  "Metal Production": ["Aluminium", "Steel"],
  "Mining/Mineral Production": ["Methane Abatement"],
  "Solvent Use": [
    "Solid Waste Management",
    "Waste Diversion",
    "Waste Water Treatment",
  ],
  Transport: ["Electric Vehicle (EV)"],
};

export const REGISTRIES = {
  "American Carbon Registry": "American Carbon Registry (ACR)",
  "Clean Development Mechanism": "Clean Development Mechanism (CDM)",
  "Climate Action Reserve": "Climate Action Reserve",
  "Global Carbon Council": "Global Carbon Council",
  "Gold Standard": "Gold Standard",
  "International Carbon Registry": "International Carbon Registry (ICR)",
  "ISO 14064-2": "ISO 14064-2",
  "Joint Crediting Mechanism": "Joint Crediting Mechanism (JCM)",
  "Plan Vivo": "Plan Vivo",
  "Puro Standard": "Puro Standard",
  "Verified Carbon Standard": "Verified Carbon Standard (Verra)",
};

export const COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic (Czechia)",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City (Holy Sea)",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const SDG_TARGETS = [
  {
    id: 1,
    name: "No Poverty",
    image: goal1,
    info: "Projects such as sustainable agriculture, forestry, and fisheries can contribute to poverty reduction by providing sustainable livelihoods and income generation opportunities for local communities.",
    description: "End poverty in all its forms and dimensions.",
    color: "#E5243B",
    images: image1,
  },
  {
    id: 2,
    name: "Zero Hunger",
    image: goal2,
    info: "Solutions such as sustainable agricultural practices, agroforestry, and fisheries management can help to increase food production and improve food security while also protecting natural resources and ecosystems.",
    description: "End hunger, achieve food security and improved nutrition.",
    color: "#DDA63A",
    images: image2,
  },
  {
    id: 3,
    name: "Good Health and Well-being",
    image: goal3,
    info: "Solutions such as green infrastructure, urban parks, and green roofs can also help to mitigate the impacts of air pollution and extreme weather events, improving public health and well-being.",
    description:
      "Ensure healthy lives and promote well-being for all at all ages.",
    color: "#4C9F38",
    images: image3,
  },
  {
    id: 4,
    name: "Quality Education",
    image: goal4,
    info: "Nature-based education programs can help to foster a deeper understanding and appreciation of the natural world, leading to greater environmental awareness and action.",
    description: "Ensure inclusive and equitable quality education. ",
    color: "#C5192D",
    images: image4,
  },
  {
    id: 5,
    name: "Gender Equality",
    image: goal5,
    info: "Nature-based projects and activities can help to address gender inequality by providing women with access to land, resources, and economic opportunities.",
    description: "Achieve gender equality and empower all women and girls. ",
    color: "#FF3A21",
    images: image5,
  },
  {
    id: 6,
    name: "Clean Water and Sanitation ",
    image: goal6,
    info: "Solutions such as watershed protection, wetland restoration, and sustainable land management practices can help to improve water quality and availability while also protecting aquatic ecosystems and biodiversity.",
    description:
      "Ensure availability and sustainable management of water and sanitation for all.",
    color: "#26BDE2",
    images: image6,
  },
  {
    id: 7,
    name: "Affordable and Clean Energy",
    image: goal7,
    info: "Solutions such as bioenergy, hydropower, and geothermal energy can provide clean and renewable sources of energy, reducing reliance on fossil fuels and mitigating climate change.",
    description:
      "Ensure access to affordable, reliable, sustainable and modern energy for all.",
    color: "#FCC30B",
    images: image7,
  },
  {
    id: 8,
    name: "Decent Work and Economic Growth",
    image: goal8,
    info: "Solutions can provide sustainable livelihoods and income generation opportunities, contributing to economic growth and reducing poverty.",
    description: "Inclusive growth, decent work for all.",
    color: "#A21942",
    images: image8,
  },
  {
    id: 9,
    name: "Industry, Innovation and Infrastructure ",
    image: goal9,
    info: "Solutions such as green infrastructure, green buildings, and sustainable transportation systems can help to reduce the environmental impacts of industry and infrastructure while also promoting sustainable economic development.",
    description: "Resilient infrastructure, industrialization, innovation.",
    color: "#FD6925",
    images: image9,
  },
  {
    id: 10,
    name: "Reduced Inequalities",
    image: goal10,
    info: "Solutions can help to reduce inequalities by providing marginalized communities with access to natural resources, economic opportunities, and other benefits.",
    description: "Reduce inequality within and among countries.",
    color: "#DD1367",
    images: image10,
  },
  {
    id: 11,
    name: "Sustainable Cities and Communities ",
    image: goal11,
    info: "Solutions such as green roofs, urban parks, and green infrastructure can help to create more livable and resilient cities, improving public health, reducing air pollution, and enhancing biodiversity.",
    description: "Make cities and settlements inclusive and sustainable.",
    color: "#FD9D24",
    images: image11,
  },
  {
    id: 12,
    name: "Responsible Consumption and Production",
    image: goal12,
    info: "Solutions such as sustainable agriculture, forestry, and fisheries can help to promote responsible consumption and production practices, reducing waste and environmental impacts.",
    description: "Ensure sustainable consumption and production.",
    color: "#BF8B2E",
    images: image12,
  },
  {
    id: 13,
    name: "Climate Action",
    image: goal13,
    info: "Solutions such as afforestation, reforestation, and sustainable land management can help to mitigate the impacts of climate change by sequestering carbon, enhancing ecosystem resilience, and reducing greenhouse gas emissions.",
    description: "Take urgent action to combat climate change and its impacts.",
    color: "#3F7E44",
    images: image13,
  },
  {
    id: 14,
    name: "Life Below Water",
    image: goal14,
    info: "Solutions such as marine protected areas, coral reef restoration, and sustainable fisheries management can help to protect and restore marine ecosystems, promoting biodiversity and ensuring the sustainable use of marine resources.",
    description:
      "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.",
    color: "#0A97D9",
    images: image14,
  },
  {
    id: 15,
    name: "Life on Land",
    image: goal15,
    info: "Solutions such as habitat restoration, reforestation, and wildlife conservation can help to protect and restore terrestrial ecosystems, promoting biodiversity, enhancing ecosystem services, and mitigating the impacts of climate change.",
    description:
      "Sustainable land use, combat desertification, preserve and restore biodiversity in ecosystems.",
    color: "#56C02B",
    images: image15,
  },
  {
    id: 16,
    name: "Peace, Justice and Strong Institutions",
    image: goal16,
    info: "Solutions such as community-based natural resource management, land tenure reform, and wildlife conservation can help to promote peace, justice, and strong institutions by providing sustainable",
    description: "Inclusive societies, justice and accountable institutions.",
    color: "#00689D",
    images: image16,
  },
  {
    id: 17,
    name: "Partnerships for the Goals",
    image: goal17,
    info: "SDG 17 is relevant in terms of fostering partnerships and collaborations between different stakeholders involved in nature-based solutions. For example, partnerships between governments, private sector, and local communities.",
    description:
      "Strengthen the means of implementation and revitalize the global partnership for sustainable development.",
    color: "#19486A",
    images: image17,
  },
];

export const DOCUMENT_FIELDS = [
  "certificateOfIncorporation",
  "taxCertificate",
  "manufacturerAuthorization",
  "baselineStudyReports",
  "stakeholderEngagement",
  "technicalFeasibility",
  "financialAnalysis",
  "letterOfAuthorization",
  "projectIdeaNote",
  "draftPDD",
  "validationReport",
  "monitoringReport",
  "verificationReport",
];

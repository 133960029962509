import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DOCUMENT_FIELDS } from "../../utils/Constants";

const initialState = {
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  projects: [],
};

export const postProject = createAsyncThunk(
  "project/postProject",
  async (projectData, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("userId", projectData.userId);
    formData.append("userEmail", projectData.userEmail);
    formData.append("nickname", projectData.nickname);

    // Example: Conditional appending for `organizationDetails` if not undefined
    if (projectData.organizationDetails !== undefined) {
      formData.append(
        "organizationDetails",
        JSON.stringify(projectData.organizationDetails)
      );
    }

    // Similar handling for sdgGoals if not undefined
    if (projectData.sdgGoals !== undefined) {
      formData.append("sdgGoals", JSON.stringify(projectData.sdgGoals));
    }

    // Handle projectImages if it's an array and not empty
    if (
      Array.isArray(projectData.projectImages) &&
      projectData.projectImages.length > 0
    ) {
      projectData.projectImages.forEach((file) =>
        formData.append("projectImages", file)
      );
    }

    DOCUMENT_FIELDS.forEach((field) => {
      if (projectData[field] && projectData[field].length > 0) {
        projectData[field].forEach((file) => {
          formData.append(field, file);
        });
      }
    });

    // Append other data fields dynamically, skipping undefined fields
    Object.entries(projectData).forEach(([key, value]) => {
      if (
        [
          "userId",
          "userEmail",
          "nickname",
          "organizationDetails",
          "sdgGoals",
          "projectImages",
        ].includes(key) ||
        value === undefined
      ) {
        return;
      }

      if (typeof value === "object") {
        // Assuming all objects should be stringified, adjust as needed
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.post(
        "/projects-service/projects",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postProject.fulfilled, (state, action) => {
        state.projects.push(action.payload);
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(postProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default projectsSlice.reducer;

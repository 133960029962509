import { lazy } from "react";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// onboard project routing
const OnboardProject = Loadable(
  lazy(() => import("views/pages/projects/onboarding/"))
);

const FAQs = Loadable(lazy(() => import("views/pages/FAQs")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MinimalLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "onboard-project",
          element: <OnboardProject />,
        },
        {
          path: "sample-page",
          element: <SamplePage />,
        },
      ],
    },
    {
      path: "faqs",
      element: <FAQs />,
    },
  ],
};

export default MainRoutes;

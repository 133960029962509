// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from "ui-component/extended/Snackbar";

import ThemeCustomization from "themes";

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { AuthProvider } from "contexts/Auth0Context";

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    <Locales>
      <NavigationScroll>
        <AuthProvider>
          <>
            <Routes />
            <Snackbar />
          </>
        </AuthProvider>
      </NavigationScroll>
    </Locales>
  </ThemeCustomization>
);

export default App;

/* eslint-disable no-unused-vars */
import { createContext, useEffect, useMemo, useState } from "react";

// project imports
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { DASHBOARD_PATH } from "config";
import { useCallback } from "react";
import axiosServices from "utils/axios";
import { toast } from "react-toastify";
import AuthErrorPage from "ui-component/AuthErrorPage";

const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axiosServices.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("token");
    delete axiosServices.defaults.headers.common.Authorization;
  }
};

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

export const Auth0Context = createContext(null);

const AuthProviderWrapper = ({ children }) => {
  const {
    isAuthenticated,
    user,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  
  const [userDetails, setUserDetails] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState(null);

  // LOGOUT
  const handleLogout = useCallback(
    async (options) => {
      logout?.(options);
    },
    [logout]
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated
    ? "authenticated"
    : "unauthenticated";

  const status = isLoading || userLoading ? "loading" : checkAuthenticated;

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          setUserLoading(true);
          const accessToken = await getAccessTokenSilently();
          setSession(accessToken);
          const userDetails = await axiosServices.get(
            "/identity-service/user/self"
          );
          setUserLoading(false);
          setUserDetails(userDetails.data.data);

          setUserError(null);
        } catch (err) {
          setUserError(err);
          setUserLoading(false);
          toast.error("Failed to get user details");
          console.log(err);
        }
      }
    };
    getToken();
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  const memoizedValue = useMemo(() => {
    return {
      user: {
        ...user,
        displayName: user?.name,
        photoURL: user?.picture,
        ...userDetails,
      },
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      isLoggedIn: status === "authenticated",
      //

      loginWithRedirect,

      logout: handleLogout,
    };
  }, [handleLogout, loginWithRedirect, status, user, userDetails]);

  if (userError) {
    return (
      <AuthErrorPage
        error="Something went wrong while logging you in"
        logout={handleLogout}
        isLoading={userLoading}
      />
    );
  }
  return (
    <Auth0Context.Provider value={memoizedValue}>
      {children}
    </Auth0Context.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI;

  const onRedirectCallback = useCallback((appState) => {
    window.location.replace(appState?.returnTo || DASHBOARD_PATH);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return <div>Auth0 is not configured</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirectUri,
        scope: "openid profile email read:users",
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};

import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import FullPageLoader from "ui-component/FullPageLoader";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, loginWithRedirect } = useAuth();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isLoggedIn) {
      loginWithRedirect({
        appState: { returnTo: (window.location.pathname + window.location.search).substring(1) },
      });
    } else {
      setChecked(true);
    }
  }, [isLoggedIn, loginWithRedirect]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuardWrapper({ children }) {
  const { loading } = useAuth();

  return (
    <>{loading ? <FullPageLoader /> : <AuthGuard> {children}</AuthGuard>}</>
  );
}
